<template>
    <layout>
        <div class="h-100 d-flex align-center logout justify-center">
            <v-card flat>
                <v-card-text class="pa-10">
                    <h2 class="text-28-60">Log out</h2>
                    <p class="mb-5 mt-3">Are you sure you want to sign out?</p>
                    <div class="d-flex">
                        <v-btn x-large class="secondary px-8 py-5 text-14 text-transform-none mr-4" :to="{name: 'account.dashboard'}" depressed>Cancel</v-btn>
                        <div class="d-flex secondary">
                            <v-btn x-large class="px-8 py-5 text-14 text-transform-none" depressed @click="logout">Sign out</v-btn>
                        </div>

                    </div>
                </v-card-text>
            </v-card>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    methods: {
        async logout () {
            await this.$store.dispatch('auth/logout')
            this.$router.push({ name: 'frontend.auth.login' })
        }
    }
}
</script>

<style lang="scss">
.logout{
    .secondary.d-flex{
        padding: 1px;
        border-radius: 5px;
    }
}
</style>
